import styled from 'styled-components'
import {
  Box as RebassBox
} from 'rebass/styled-components'

export const InnerBox = styled(RebassBox)`
  margin: 3rem 6rem;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: 1rem;
  }
`

export const Box = styled(RebassBox)`
  background-color: ${props => props.theme.colors.content.background};
  border: ${props => props.theme.content.border};
  border-radius: ${props => props.theme.content.borderRadius};
  box-shadow: ${props => props.theme.content.boxShadow};
  margin: 4rem 0 0;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: 1rem 0 0;
  }

  p, li, blockquote {
    font-family: ${props => props.theme.font.body};
    font-weight: 600;
    font-size: 16px;
    line-height: 1.8rem;
    letter-spacing: 0.02rem;
    text-align: justify;
    margin-bottom: 1rem;
  }

  p a {
    color: ${props => props.theme.colors.sidebar.activeLink};
    border-bottom: 1px dashed ${props => props.theme.colors.sidebar.link};
  }

  p a:hover {
    color: ${props => props.theme.colors.sidebar.link};
    border-bottom: 1px dashed ${props => props.theme.colors.sidebar.activeLink};
  }

  h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 2rem 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 2rem 0;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 2rem 0;
  }

  ol {
    list-style-type: decimal-leading-zero;
    margin-left: 1rem;
  }

  ol li {
    margin-left: 1rem;
  }

  blockquote {
    position: relative;

    &:before {
      border-left: 0.5rem solid ${props => props.theme.colors.border};
      content: "";
      display: block;
      float: left;
      padding: 0 0 2rem;
      top: 0;
      left: 0;
      bottom: 0;
      margin: 0 0 3rem;
    }
  }

  sup.footnote-ref {
    font-size: 0.6rem;
    opacity: 0.4;
    vertical-align: super;
  }

  sup.footnote-ref:hover {
    opacity: 1;
  }

  .footnotes-sep {
    background-color: ${props => props.theme.colors.sidebar.border};
    color: ${props => props.theme.colors.sidebar.border};
    height: 1px;
    opacity: 0.2;
    margin: 4rem auto;
    width: 60%;
  }

  .footnotes {
    margin: 4rem 0;
    opacity: 0.6;
    padding: 0 0 1rem;
  }

  .footnotes:hover {
    opacity: 1;
  }

  .footnotes p {
    font-size: 14px;
  }

  .tagName {
    font-weight: bolder;
    font-size: 20px;
    font-family: monospace;
  }
`
