import React, { ReactNode, FunctionComponent, useEffect } from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-yaml'
import 'prismjs/themes/prism-okaidia.css'

import { Box, InnerBox } from './styled'

interface Props {
  children: ReactNode
}

const ContentBox: FunctionComponent<Props> = ({ children }: Props) => {
  useEffect(() => {
    setTimeout(() => Prism.highlightAll(), 0)
  }, [])

  return (
    <Box>
      <InnerBox>
        {children}
      </InnerBox>
    </Box>
  )
}

export default ContentBox
